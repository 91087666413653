<template>
  <div v-if="isSerialSupported" class="serial-port-container">
    <h1 class="section-margin">Web Serial Extension</h1>
    <div class="flex-row">
      <Button id="connect-btn" class="flex-grow" @click="connectAndListenToPort" :disabled=portRef>Connect Port</Button>
      <Dropdown v-model="baudRate" :options="baudRates" placeholder="Select Baud rate" class="w-full md:w-14rem" />
      <Button id="clear-logs-btn" @click="clearLogs">clear</Button>
      <Button icon="pi pi-refresh" rounded aria-label="Filter" @click="refreshConnection" />
    </div>
    <div class="log-section" ref="logRef"></div>
    <div class="flex-row">
      <InputText class="flex-grow" v-model="message" @keyup.enter="send" placeholder="Input"/>
      <Button @click="send" :disabled = !portRef>Send</Button>
    </div>
  </div>
  <div v-else>Serial is not supported in this browser. Please update your browser</div>
</template>

<script setup lang="ts">

import { ref } from 'vue';
import Dropdown from 'primevue/dropdown';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';

const logRef = ref<HTMLElement>();
const message = ref('');
const isSerialSupported = ref("serial" in navigator)
const portRef = ref(null)
const baudRate = ref(115200)
const baudRates = ref([110,300,600,1200,2400,4800,9600,14400,19200,38400,57600,115200,128000,256000]);
const writerRef = ref()
const readerRef = ref()
const readableStreamClosedRef = ref()
const writableStreamClosedRef = ref()
const endPortConnection = ref(false)

const connectAndListenToPort = async () => {

  if(!await connectToPort())
    return
  
  endPortConnection.value = false

  //setup writer
  if(!setupWriter())
  {
    alert("Failed to setup Writer. Please check port connection")
    return
  }

  //setup reader 
  if(!setupReader())
  {
    alert("Failed to setup Reader. Please check port connection")
    return
  }

  //start listener
  listenToPort()
};

const connectToPort = async() => {
  let isConnected = false
  try{
    const port = await navigator.serial.requestPort()

    portRef.value = port

    console.log("check for port name in port",port)

    // Wait for the serial port to open.
    await port.open({ baudRate: baudRate.value ?? 9600 });

    // Turn on Data Terminal Ready (DTR) and Request To Send (RTS) signals.
    //await port.setSignals({ dataTerminalReady: true, requestToSend: true  });

    isConnected = true
  }catch(error)
  {
    alert(error)
  }

  return isConnected
}

const setupWriter = ()=>{

  if(!portRef.value) return false

  //setup writer
  const textEncoder = new TextEncoderStream();
  writableStreamClosedRef.value = textEncoder.readable.pipeTo(portRef.value.writable);
  writerRef.value = textEncoder.writable.getWriter();
  return true

}

const setupReader = () => {

  if(!portRef.value) return false

  const textDecoder = new TextDecoderStream();
  readableStreamClosedRef.value = portRef.value.readable.pipeTo(textDecoder.writable);
  const reader = textDecoder.readable.getReader();
  readerRef.value = reader

  return true
}

const listenToPort = async ()=> {

  // Listen to data coming from the serial device.
  while (portRef.value && portRef.value.readable) {
    try {
      while (!endPortConnection.value) {
        const { value, done } = await readerRef.value.read();
        if (done) {          
          break;
        }

        addToLogs(value)

      }
    } catch (error) {
      console.error(error)      
    } finally {
      // Allow the serial port to be closed later.
      readerRef.value.releaseLock()
    }
  }
}

const addToLogs = (dataToPrint:string) => {
  logRef.value.innerHTML+=dataToPrint

  //auto scroll bottom
  logRef.value.scrollTop = logRef.value.scrollHeight;
}

const send = async () => {

  if(writerRef.value && message.value.trim())
  {
    await writerRef.value.write(message.value+"\r\n");

    //clear input field
    message.value = ""
  }
  else
    console.error("Empty message or no port connected")
};

const refreshConnection = () => {
  window.location.reload()
}

const clearLogs = () => {
  logRef.value.innerHTML = ""
}

</script>

<style lang="less">

body{
  margin: 0;
  padding: 0;
}

#app{
  width: 500px;
  position: absolute;
  background-color: #e6ccff;
}

.serial-port-container{
  padding: 20px;
  display: flex;
  flex-direction: column;
  column-gap: 10px;
  row-gap: 20px;
}

.log-section{
  background-color: white;
  white-space: pre;
  height: 200px;
  border-radius: 5px;
  max-height: 200px;
  overflow-y: auto;
  padding: 0 10px;
  .serial-log-msg{
    margin: 0px;
  }
}

.white-bg{
  background-color: white;
  color: black;
}

.section-margin{
  margin:10px
}

.full-w{
  width:100%
}

.flex-row{
  display: flex;
  justify-content: space-between;
  column-gap: 15px;
}

.flex-grow{
  flex-grow: 1;
}
</style>
